import React, {useState, useEffect} from "react";
import {
    appWithTranslation,
    getCookie,
    setCookie,
    Router,
    useTranslation,
} from "@/util/helper";
import NProgress from "nprogress";
import moment from "moment";
import "react-dates/initialize";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dates/lib/css/_datepicker.css";
import "./global.css";
import SidebarContext from "@/context/SidebarContext";
import sentry from "@/util/sentry";
import * as smoothscroll from "smoothscroll-polyfill";
import {
    initializeGoogleAnalytics,
    registerPageView,
} from "@/util/googleAnalytics";
import {getOrInitializeStore} from "@/util/reduxUtils";
import {selectCompany} from "@/redux/selectedCompany";
import {PAYOUTS_TOKEN, SELECTED_COMPANY} from "@/util/constants";
import {getSafeDeep} from "@/util/state";
import auth from "@/api/auth/auth";
import {useRouter} from "next/router";
import getConfig from "next/config";

const {captureException} = sentry();
const { publicRuntimeConfig } = getConfig();


Router.events.on("routeChangeStart", (url) => {
    console.log(`Loading: ${url}`);
    NProgress?.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const MyApp = ({Component, pageProps, hasError, errorEventId, companies}) => {
    const [chooseCompany, setChooseCompany] = useState(false);
    const router = useRouter()
    useEffect(() => {
        const onFocus = async () => {
            try {
                const res = await auth().getUser()
                if (router.pathname == "/login") {
                    router.push('/')
                }
            } catch (e) {
                if (e.response.status == 403 || e.response.status == 401) {
                    window.location.replace(`${publicRuntimeConfig.ellyManagerURL}/login`);
                }
            }
        };
        window.addEventListener('focus', onFocus);
        return () => window.removeEventListener("focus", onFocus)
    }, []);


    useEffect(() => {
        // Add sidebar open class for 769-1049px datepicker modal on payments
        document.body.classList.add("sidebarOpen");

        // TODO: bundle the polyfill into webpack config - this was a quick fix
        smoothscroll.polyfill();

        // Google anayltics tracking
        if (!window.GA_INITIALIZED) {
            initializeGoogleAnalytics();
            window.GA_INITIALIZED = true;
        }
        registerPageView();

        auth()
            .authPayouts()
            .then(({data}) => setCookie(PAYOUTS_TOKEN, data?.access_token))
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        const localSelectedCompany = getCookie(SELECTED_COMPANY);
        const store = getOrInitializeStore();
        const state = store.getState();

        if (
            !localSelectedCompany &&
            getSafeDeep(state, "common.companies.length") > 1
        ) {
            setChooseCompany(true);
        }

        if (!state.selectedCompany) {
            if (!localSelectedCompany) {
                // no previously selected company
                if (getSafeDeep(state, "common.companies.length") > 1) {
                    setChooseCompany(true);
                }
                const company = getSafeDeep(state, "common.companies.0");
                store.dispatch(selectCompany(company));
            } else {
                // previously selected company
                if (getSafeDeep(!state, "common.companies.length") > 1) {
                    setChooseCompany(true);
                }
                const company = JSON.parse(localSelectedCompany);
                store.dispatch(selectCompany(company));
            }
        }
    }, [companies]);

    // Sidebar state and handlers for persisting in SSR navigation
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        if (sidebarOpen) {
            document.body.classList.remove("sidebarOpen");
        } else {
            document.body.classList.add("sidebarOpen");
        }
        setSidebarOpen(!sidebarOpen);
    };

    // Moment localization
    const {i18n} = useTranslation();
    moment.updateLocale(i18n.language, {
        week: {
            dow: 1,
            doy: 1,
        },
    });

    return (
        <SidebarContext.Provider
            value={{
                sidebarOpen,
                toggleSidebar,
                chooseCompany,
                setChooseCompany,
            }}
        >
            <div
                onClickCapture={(e) => {
                    e.stopPropagation();
                }}
                id="screenLoader"
            >
                <div className="screen-pulse screen-loader-indicator"/>
            </div>
            <Component {...pageProps} />
        </SidebarContext.Provider>
    );
};

MyApp.getInitialProps = async ({Component, ctx}) => {
    try {
        let pageProps = {};

        if (Component && Component.getInitialProps) {
            pageProps = (await Component.getInitialProps(ctx)) || {};
        }
        if (!pageProps.namespacesRequired) {
            pageProps.namespacesRequired = ["common"];
            // eslint-disable-next-line no-console
            console.warn(
                `No translation namespace requested in page ${Component.name}`
            );
        }

        const store = ctx.reduxStore;
        let companies = [];

        if (store && store.getState) {
            const state = store.getState();
            companies = getSafeDeep(state, "common.companies", []);
        }

        return {pageProps, companies};
    } catch (e) {
        const errorEventId = captureException(e, ctx);
        throw e;
    }
};

export default appWithTranslation(MyApp);
